import { FireMasterTheme, bonhamsTheme } from '@nx/fire/bonhams-theme-master'
import { cornetteTheme } from '@nx/fire/cornette-theme'
import { skinnerTheme } from '@nx/fire/skinner-theme'
import { Brand, EBrand } from '@nx/global-types'

export function getTheme(brand?: EBrand): FireMasterTheme {
  switch (brand) {
    case Brand.skinner:
      return skinnerTheme
    case Brand.cornette:
      return cornetteTheme
    case Brand.bonhams:
    default:
      return bonhamsTheme
  }
}
