import getConfig from 'next/config'

import { useGetLaunchDarklyFlags as useGetLaunchDarklyFlagsLib } from '@nx/external/launch-darkly-client'

import { launchDarklyFeatureFlags } from './flags'

const { publicRuntimeConfig } = getConfig()

/* istanbul ignore next */
export const useGetLaunchDarklyFlags = () =>
  useGetLaunchDarklyFlagsLib<`${launchDarklyFeatureFlags}`>(
    publicRuntimeConfig.LAUNCHDARKLY_CLIENT_KEY
  )
