import getConfig from 'next/config'

import { GetLaunchDarklyFlagServerProps } from '@nx/external/launch-darkly-common'
import {
  getAllLaunchDarklyFlagsServer as getAllLaunchDarklyFlagsServerLib,
  getLaunchDarklyFlagServer as getLaunchDarklyFlagServerLib,
} from '@nx/external/launch-darkly-server'

import { launchDarklyFeatureFlags } from './flags'

const { serverRuntimeConfig } = getConfig()

/* istanbul ignore next */
export const getLaunchDarklyFlagServer = ({
  ldId,
  key,
}: {
  ldId: GetLaunchDarklyFlagServerProps['ldId']
  key: `${launchDarklyFeatureFlags}`
}) =>
  getLaunchDarklyFlagServerLib<boolean>(
    { ldId, sdkKey: serverRuntimeConfig.LAUNCHDARKLY_SDK_KEY },
    key
  )

/* istanbul ignore next */
export const getAllLaunchDarklyFlagsServer = ({
  ldId,
}: {
  ldId: GetLaunchDarklyFlagServerProps['ldId']
}) =>
  getAllLaunchDarklyFlagsServerLib<`${launchDarklyFeatureFlags}`>({
    ldId,
    sdkKey: serverRuntimeConfig.LAUNCHDARKLY_SDK_KEY,
  })
