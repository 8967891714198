import { useQuery } from '@tanstack/react-query'
import Cookies from 'js-cookie'
import * as LaunchDarklyJSClient from 'launchdarkly-js-client-sdk'

import {
  LD_ID_COOKIE_NAME,
  LD_QUERY_KEY,
} from '@nx/external/launch-darkly-common'

let launchDarklyClientClient: LaunchDarklyJSClient.LDClient | undefined

export async function getLaunchDarklyJSClient(sdkKey: string) {
  if (launchDarklyClientClient) return launchDarklyClientClient

  launchDarklyClientClient = LaunchDarklyJSClient.initialize(sdkKey, {
    kind: 'user',
    key: Cookies.get(LD_ID_COOKIE_NAME) || '1',
  })
  await launchDarklyClientClient.waitForInitialization(5)
  await launchDarklyClientClient.waitUntilReady()

  return launchDarklyClientClient
}

export function useGetLaunchDarklyFlags<T extends string>(ldClientId: string) {
  return useQuery<Record<T, boolean>>({
    queryKey: LD_QUERY_KEY,
    queryFn: async () => {
      const client = await getLaunchDarklyJSClient(ldClientId)
      return client.allFlags() as Record<T, boolean>
    },
  })
}

export { launchDarklyClientClient }
