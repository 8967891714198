import type * as LaunchDarkly from 'launchdarkly-node-server-sdk'

import {
  GetLaunchDarklyFlagServerProps,
  getLaunchDarklyKey,
} from '@nx/external/launch-darkly-common'

let launchDarklyClient: LaunchDarkly.LDClient

async function initialize(sdkKey: string) {
  const { default: LaunchDarkly } = await import('launchdarkly-node-server-sdk')

  const client = LaunchDarkly.init(sdkKey)
  await client.waitForInitialization()
  return client
}

export async function getLaunchDarklyClientServer(sdkKey: string) {
  if (!launchDarklyClient) {
    launchDarklyClient = await initialize(sdkKey)
  }

  return launchDarklyClient
}

export async function getAllLaunchDarklyFlagsServer<T extends string>({
  sdkKey,
  ldId,
}: GetLaunchDarklyFlagServerProps) {
  const client = await getLaunchDarklyClientServer(sdkKey)
  return (
    await client.allFlagsState({
      key: getLaunchDarklyKey({ ldId }).key,
    })
  ).allValues() as unknown as Record<T, boolean>
}

export async function getLaunchDarklyFlagServer<T>(
  { sdkKey, ldId }: GetLaunchDarklyFlagServerProps,
  key: string
): Promise<T | undefined> {
  const client = await getLaunchDarklyClientServer(sdkKey)
  return (
    await client.allFlagsState({
      key: getLaunchDarklyKey({ ldId }).key,
    })
  ).getFlagValue(key)
}
