import { useEffect } from 'react'

import { useDataLayer } from '@nx/utils/google-tag-manager'

import { useGetLaunchDarklyFlags } from '@web/helpers/api/launchDarkly'

const getCommaSeparatedValues = (obj: Record<string, unknown>) =>
  Object.entries(obj)
    .map(([key, value]) => `${key}:${value}`)
    .join(';')

export const useUserFeatureFlagTracking = () => {
  const dataLayer = useDataLayer()
  const { data: flags } = useGetLaunchDarklyFlags()

  useEffect(() => {
    if (!window?.dataLayer?.some((item) => item?.featureFlags)) {
      if (flags && Object.keys(flags).length) {
        dataLayer.push({
          featureFlags: getCommaSeparatedValues(flags),
        })
      }
    }
  }, [dataLayer, flags])
}
