import { AxiosInstance } from 'axios'

export const requestTokenRefresh = async ({
  authenticatedAxiosClient,
  refreshToken,
}: {
  authenticatedAxiosClient: AxiosInstance
  refreshToken: string
}) =>
  authenticatedAxiosClient.post('/api/token/refresh/', {
    refresh: refreshToken,
  })
