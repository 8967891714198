var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"$CI_COMMIT_SHORT_SHA"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/* istanbul ignore file */
import * as Sentry from '@sentry/nextjs'

import { generateClientConfig } from '@nx/utils/sentry-config'

const beforeSend = (event: unknown) => {
  try {
    const eventString = JSON.stringify(event)

    const isErrorToIgnore = [
      [
        'am-auction-widget',
        'The request was rejected because the new request served',
      ],
      ['am-auction-widget', 'Too Many Requests'],
      ['localStorage.getItem'],
      ['evergage'],
      ['error: https://js-agent.newrelic.com/'],
      ["Can't find variable: gmo"],
    ].some((errorStrings) =>
      errorStrings.every((errorString) => eventString.includes(errorString))
    )

    return isErrorToIgnore ? null : event
  } catch {
    return event
  }
}

Sentry.init(
  generateClientConfig({
    dsn: 'https://614543fea9dc4eee80879fcc1c88e34a@o1042976.ingest.sentry.io/6036392',
    preprodHost: 'preprod-www01.bonhams.com',
    beforeSend,
  })
)
