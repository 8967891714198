import { forwardRef } from 'react'
import { useTheme } from 'styled-components'

import { FireMasterTheme } from '@nx/fire/bonhams-theme-master'
import { FireWeChatPopup } from '@nx/fire/we-chat-popup'
import { getTranslate } from '@nx/translations'

import { NewsletterForm } from './NewsletterForm'
import {
  Footer,
  FooterButton,
  FooterContainerDiv,
  FooterContainerLeft,
  FooterContainerRight,
  FooterCopyright,
  FooterLink,
  FooterLinksDiv,
  FooterLinksItemDiv,
  FooterLogoImg,
  FooterSocialLink,
  SocialDiv,
  SocialItemDiv,
} from './fire-footer.styles'
import translate from './fire-footer.translate.json'

const { text } = getTranslate(translate)

export const FireFooter = forwardRef<HTMLDivElement, FireFooterProps>(
  (
    {
      logoTitle,
      links,
      socialLinks,
      oneTrust,
      weChatEnabled,
      formData,
      ...props
    },
    ref
  ) => {
    const theme: FireMasterTheme = useTheme()
    const year = new Date().getFullYear()

    return (
      <Footer {...props} ref={ref}>
        <FooterContainerDiv>
          <FooterContainerLeft>
            <FooterLogoImg as={theme.footerLogo.component} title={logoTitle} />

            <FooterLinksDiv>
              {links.map((link) => (
                <FooterLinksItemDiv key={`${link.href}-${link.text}`}>
                  <FooterLink href={link.href} forceAnchor={link.forceAnchor}>
                    {link.text}
                  </FooterLink>
                </FooterLinksItemDiv>
              ))}

              {oneTrust.enabled && (
                <FooterLinksItemDiv>
                  <FooterButton
                    variant="ghost"
                    onClick={() => window.OneTrust?.ToggleInfoDisplay()}
                  >
                    {oneTrust.text}
                  </FooterButton>
                </FooterLinksItemDiv>
              )}
            </FooterLinksDiv>
          </FooterContainerLeft>
          <FooterContainerRight>
            <NewsletterForm {...formData} />

            <SocialDiv>
              {socialLinks.map((link) => (
                <FooterSocialLink
                  href={link.href}
                  key={`${link.href}}`}
                  target="_blank"
                >
                  {link.icon}
                </FooterSocialLink>
              ))}

              {weChatEnabled && (
                <SocialItemDiv data-testid="wechat">
                  <FireWeChatPopup isOnlyIcon />
                </SocialItemDiv>
              )}
            </SocialDiv>
            <FooterCopyright $level="XS">
              {text('copyright')} &copy; Bonhams 2001-{year}
            </FooterCopyright>
          </FooterContainerRight>
        </FooterContainerDiv>
        <FooterContainerDiv></FooterContainerDiv>
      </Footer>
    )
  }
)

export interface FireFooterProps {
  logoTitle: string
  links: {
    text: string
    href: string
    forceAnchor?: boolean
  }[]
  oneTrust: {
    enabled: boolean
    text: string
  }
  socialLinks: {
    icon: JSX.Element
    href: string
  }[]
  weChatEnabled?: boolean
  formData: {
    title?: string
    supportingText?: string
    placeholder?: string
    submit?: string
    apiBaseUrl: string
  }
}

export default FireFooter
