import { ForwardedRef, forwardRef } from 'react'

import {
  StyledButton,
  StyledEndIcon,
  StyledFireLink,
  StyledStartIcon,
} from './FireButton.styles'
import { AnchorProps, ButtonProps, FireInnerButtonProps } from './types'

const hasHref = (props: FireButtonProps): props is AnchorProps =>
  'href' in props && !!props.href

const InnerButton = ({
  startIcon,
  endIcon,
  children,
  size,
}: FireInnerButtonProps) => (
  <>
    {startIcon && <StyledStartIcon as={startIcon} $size={size} />}
    {children}
    {endIcon && <StyledEndIcon as={endIcon} $size={size} />}
  </>
)

export type FireButtonProps = ButtonProps | AnchorProps

export const FireButton = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  FireButtonProps
>((props, ref) => {
  if (hasHref(props)) {
    const {
      startIcon,
      endIcon,
      children,
      variant = 'primary',
      href,
      size = 'M',
      className,
      ...linkProps
    } = props

    const forwardedRef = ref as ForwardedRef<HTMLAnchorElement>

    return (
      <StyledFireLink
        {...linkProps}
        href={href}
        $variant={variant}
        ref={forwardedRef}
      >
        <StyledButton
          className={className}
          $startIcon={startIcon}
          $endIcon={endIcon}
          $variant={variant}
          $size={size}
          as="span"
        >
          <InnerButton {...props} size={size} />
        </StyledButton>
      </StyledFireLink>
    )
  }

  const forwardedRef = ref as ForwardedRef<HTMLButtonElement>

  const {
    startIcon,
    endIcon,
    children,
    variant = 'primary',
    size = 'M',
    ...buttonProps
  } = props

  return (
    <StyledButton
      {...buttonProps}
      ref={forwardedRef}
      $startIcon={startIcon}
      $endIcon={endIcon}
      $variant={variant}
      $size={size}
    >
      <InnerButton
        children={children}
        startIcon={startIcon}
        endIcon={endIcon}
        size={size}
      />
    </StyledButton>
  )
})
